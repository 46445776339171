import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import axios from "axios";
import VueAxios from 'vue-axios'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(VueAxios, axios)
Vue.prototype.$log = console.log.bind(console)

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://agritech.mosaique.link:8080/';
// axios.defaults.baseURL = 'http://agritech.mosaique.link:8001/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/';   

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
