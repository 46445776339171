import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views

/* ........................Admin........................................................ */
const Data = () => import('@/views/admin/data/Data')

const AdminDashboard = () => import('@/views/admin/dashboard/AdminDashboard')
const AdminGraphDetails= () => import('@/views/admin/dashboard/AdminGraphDetails')

const BankUsersTable = () => import('@/views/admin/users/bankUsers/BankUsersTable')
const FarmerUsersTable = () => import('@/views/admin/users/farmerUsers/FarmerUsersTable')
const AdminUsersTable = () => import('@/views/admin/users/adminUsers/AdminUsersTable')

const AdminUserProfile= () => import('@/views/admin/profile/AdminUserProfile')
const EditAdminUserProfile= () => import('@/views/admin/profile/EditAdminUserProfile')

const BankNewAccount= () => import('@/views/admin/users/bankUsers/BankNewAccount')
const FarmerNewAccount= () => import('@/views/admin/users/farmerUsers/FarmerNewAccount')
const AdminNewAccount= () => import('@/views/admin/users/adminUsers/AdminNewAccount')

const BankEditAccount= () => import('@/views/admin/users/bankUsers/BankEditAccount')
const FarmerEditAccount= () => import('@/views/admin/users/farmerUsers/FarmerEditAccount')
const AdminEditAccount= () => import('@/views/admin/users/adminUsers/AdminEditAccount')

const NewTypeUser= () => import('@/views/admin/master/NewTypeUser')
const NewFarmerCategory= () => import('@/views/admin/master/NewFarmerCategory')
const EditFarmerCategory= () => import('@/views/admin/master/EditFarmerCategory')
const Master = () => import('@/views/admin/master/Master')
const EditTypeofUser= () => import('@/views/admin/master/EditTypeofUser')


/* ........................Farmer....................................................... */
const FarmerDashboard = () => import('@/views/farmer/dashboard/FarmerDashboard')
const FarmerData = () => import('@/views/farmer/FarmerData')
const FarmerUserProfile = () => import('@/views/farmer/profile/FarmerUserProfile')
const EditFarmerUserProfile= () => import('@/views/farmer/profile/EditFarmerUserProfile')

const FarmerGraphDetails= () => import('@/views/farmer/graph/FarmerGraphDetails')

/* ........................Bank....................................................... */
const BankDashboard = () => import('@/views/bank/dashboard/BankDashboard')
const BankUserProfile = () => import('@/views/bank/profile/BankUserProfile')
const EditBankUserProfile= () => import('@/views/bank/profile/EditBankUserProfile')

const UserGraphDetails= () => import('@/views/bank/graph/UserGraphDetails')

/* ............................................................................................................ */

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const ForgotPwd = () => import('@/views/pages/ForgotPwd')
const Register = () => import('@/views/pages/Register')

/* ............................................................................................................ */



Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/pages/login',
      component: TheContainer,
      
      children: [

        /* .............................Admin routes................................................ */
        {
          path: 'adminDashboard',
          name: 'Dashboard',
          component: AdminDashboard
        },

        {
          path: 'data',
          name: 'Data',
          component: Data
        },
        {
          path: 'user',
          name: 'User',
          component: BankUsersTable
        },
        {
          path: 'farmer',
          name: 'Farmer',
          component: FarmerUsersTable
        },
        {
          path: 'admin',
          name: 'Admin',
          component: AdminUsersTable
        },
        {
          path: 'master',
          name: 'Master',
          component: Master
        },

        {
          path: 'bankNewAccount',
          name: 'Add new user',
          component: () => import('@/views/admin/users/bankUsers/BankNewAccount.vue'),
        },
        {
          path: 'farmerNewAccount',
          name: 'Add new farmer',
          component: FarmerNewAccount
        },
        {
          path: 'adminNewAccount',
          name: 'Add new admin',
          component: AdminNewAccount
        },
        {
          path: 'adminEditAccount',
          name: 'Edit account',
          component: AdminEditAccount,
          props: true
        },
        {
          path: 'farmerEditAccount',
          name: 'Edit farmer account',
          component: FarmerEditAccount,
          props: true
        },
        {
          path: 'bankEditAccount',
          name: 'Edit user account',
          component: BankEditAccount,
          props: true
        },
        {
          path: 'newTypeUser',
          name: 'Add new user type',
          component: NewTypeUser
        },
        {
          path: 'newFarmerCategory',
          name: 'Add new farmer category',
          component: NewFarmerCategory
        },

        {
          path: 'editFarmerCategory',
          name: 'Edit new farmer category',
          component: EditFarmerCategory,
          props: true
        },
      
        {
          path: 'adminProfile',
          name: 'My profile',
          component: AdminUserProfile
        },

        {
          path: 'editAdminUserProfile',
          name: 'Edit admin profile',
          component: EditAdminUserProfile,
          props: true
        },

        {
          path: 'adminGraphDetails',
          name: 'Graph Details',
          component: AdminGraphDetails,
          props: true
        },

        {
          path: 'editTypeofUser',
          name: 'Edit type of user details',
          component: EditTypeofUser,
          props: true
        },

        /* .............................Farmer routes................................................ */
        {
          path: 'farmerDashboard',
          name: 'Dashboard',
          component: FarmerDashboard
        },

        {
          path: 'farmerData',
          name: 'Data',
          component: FarmerData
        },
        {
          path: 'farmerProfile',
          name: 'My profile',
          component: FarmerUserProfile
        },

        {
          path: 'editFarmerUserProfile',
          name: 'Edit Farmer User Profile',
          component: EditFarmerUserProfile,
          props: true
        },

        {
          path: 'farmerGraphDetails',
          name: 'Graph Details',
          component: FarmerGraphDetails,
        },

        /* .............................Bank routes................................................ */
        {
          path: 'bankDashboard',
          name: 'Dashboard',
          component: BankDashboard
        },
        {
          path: 'bankProfile',
          name: 'My profile',
          component: BankUserProfile,
        },

        {
          path: 'editBankUserProfile',
          name: 'Edit User Profile',
          component: EditBankUserProfile,
          props: true
        },

        {
          path: 'userGraphDetails',
          name: 'Graph Details',
          component: UserGraphDetails,
        },
      ]
    },

    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'forgotpwd',
          name: 'ForgotPwd',
          component: ForgotPwd
        }
      ]
    }
  ]
}

